import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/@pauliescanlon/gatsby-theme-terminal/src/layouts/PageLayout.js";
import { Link as GatsbyLink } from "gatsby";
import { PostCard } from "../components/PostCard";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SourceList = makeShortcode("SourceList");
const Flex = makeShortcode("Flex");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`posts`}</h1>
    <SourceList filter="posts" mdxType="SourceList">
  {posts => {
        return <Flex sx={{
          flexWrap: "wrap",
          ml: theme => `-${theme.space[2]}px`,
          mr: theme => `-${theme.space[2]}px`
        }} mdxType="Flex">
        {posts.filter(edge => !edge.node.frontmatter.isPrivate).reduce((posts, post) => {
            return post.node.frontmatter.pinned ? [post, ...posts] : [...posts, post];
          }, []).map((edge, index) => {
            const {
              frontmatter: {
                title,
                featuredImageUrl,
                tags,
                date,
                dateModified,
                pinned
              },
              excerpt,
              timeToRead,
              fields: {
                slug
              }
            } = edge.node;
            return <PostCard key={index} title={title} cardImage={index === 0 ? featuredImageUrl : null} tags={tags} date={date} dateModified={dateModified} excerpt={excerpt} slug={slug} pinned={pinned} timeToRead={timeToRead} mdxType="PostCard" />;
          })}
      </Flex>;
      }}
    </SourceList>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      